import {useEffect, useState} from 'react'
import Events from './Events'
import BuildEvents from './BuildEvents'
import FooterDashboard from './FooterDashbaord'
import Icon from './subComp/Icon'
import { useNavigate } from 'react-router-dom'
import useMobile from './useMobile'
export default function Dashboard() {
    const navigate = useNavigate()
    const [typeBoard, setTypeBoard] = useState('events')
    const [events, setEvents] = useState([])
  const {isMobile} = useMobile()
    return <div className="flex flex-col w-[100%] h-[100vh]">
    <nav className="flex items-center justify-between px-6 py-2 lg:px-8" aria-label="Global">
      <div className='flex flex-row'>

        <div className="flex lg:flex-1 pr-8">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt=""/>
          </a>
        </div>
   
        <div className="hidden lg:flex lg:gap-x-12">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Product</a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Features</a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Marketplace</a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Company</a>
        </div>
        </div>
  
        <div  className="hidden lg:flex lg:gap-x-12 pl-12">
            <button type="button" class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
            </button>
          </div>
      </nav>
        <div className="flex flex-row w-[100%] flex-1 bg-gray-200">
        {typeBoard !== "build-events"&& <div className="w-[100%] h-[100%] flex flex-row justify-center items-center">
     {/* <div
        style={{ zIndex: 5 }}
        className="absolute bottom-[78px] left-[8px] p-1 text-sm rounded-[5px] w-[150px] h-[30px] px-4 gap-2 flex justify-center items-center flex-row bg-orange-600 text-white">
        <Icon name="plus" size={20} color="white" onClick={() => {setTypeBoard("build-events")}} />
        Add event
      </div> */}
      <div>
        <div className='absolute flex flex-col items-center gap-2 bottom-[80px] left-8 bg-blue'
        
        >
          <div className='w-[45px] shadow flex justify-center items-center rounded-[45px] bg-white h-[45px]'onClick={()=>navigate('/edit')}><Icon name="edit" size={25}  /></div>
          <div className='w-[45px] shadow flex justify-center items-center rounded-[45px] bg-white h-[45px]' onClick={()=>navigate('/camera')}><Icon name ="camera" size={25}/></div>
          <div className='w-[45px] shadow flex justify-center items-center rounded-[45px] bg-white h-[45px]' onClick={()=>navigate('/scanner')}><Icon name ="scaner" size={25}/></div>
          <div className='w-[45px] shadow flex justify-center items-center rounded-[45px] bg-white h-[45px]'onClick={()=>setTypeBoard("build-events")}><Icon name ="plus" size={25} /></div>
        </div>

        </div>
        </div>}
            <div className="flex flex-row w-[100%] flex-1 bg-gray-200">
            {typeBoard =="events" && <Events events={events} setEvents={setEvents} setType={setTypeBoard}/>}
            {typeBoard =="build-events" && <BuildEvents setEvents={setEvents} setTypeBoard={setTypeBoard}/>}

            </div>
            {!isMobile ?
            <div className="flex flex-col gap-2 w-[250px] bg-white">
                <h5 className='p-2 text-center'>Menu</h5>
                <div 
                onClick={()=>setTypeBoard('events')}
                className={`w-[100%] h-[40px]  ${typeBoard == 'events' ? 'text-white':'text-gray-900'}  ${typeBoard == 'events' ? 'bg-blue-600': 'bg-white'} flex flex-row justify-between items-center px-2 text-sm font-semibold`}><span>Events</span>
                    <span>h</span>
                    </div>
                <div 
                onClick={()=>setTypeBoard('build-events')}
                className={`w-[100%] h-[40px]  ${typeBoard == 'build-events' ? 'text-white':'text-gray-900'}  ${typeBoard == 'build-events' ? 'bg-blue-600': 'bg-white'} flex flex-row justify-between items-center px-2 text-sm font-semibold`}><span>Build Events</span>
                <span>h</span>
                </div>
            </div>:
            <FooterDashboard type={typeBoard} setType={setTypeBoard}/>
            }
        </div>
    </div>

}