import { BsBrush, BsCursorFill, BsEmojiGrin, BsQrCodeScan } from "react-icons/bs"
import { FaRegSmile } from "react-icons/fa"
import { FaCropSimple, FaRegBuilding, FaRegLightbulb } from "react-icons/fa6"
import { IoIosFootball,IoIosSettings,IoMdArrowRoundBack } from "react-icons/io"
import { IoCameraOutline, IoFastFoodOutline, IoHome, IoShareSocialOutline } from "react-icons/io5"
import { ImCross } from "react-icons/im";
import { MdDeleteOutline, MdEmojiSymbols, MdFileUpload, MdOutlineAdd } from "react-icons/md"
import { PiDog, PiStickerFill, PiTextTBold } from "react-icons/pi"
import { AiOutlineSave } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
export default function Icon(props) {
    const { name, size, color, choose, onClick } = props
    const colorChoose = "red"
    return <>
        {name == 'delete' && <MdDeleteOutline size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'plus' && <MdOutlineAdd size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'crop' && <FaCropSimple size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'emoji' && <BsEmojiGrin size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'text' && <PiTextTBold size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'sticker' && <PiStickerFill size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'smile' && <FaRegSmile size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'brush' && <BsBrush size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'upload' && <MdFileUpload size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'dog' && <PiDog size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'building' && <FaRegBuilding size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'football' && <IoIosFootball size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'lightbulb' && <FaRegLightbulb size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'food' && <IoFastFoodOutline size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {/* {name == 'emoji' && <MdEmojiSymbols size={size} color={choose? colorChoose:color} onClick={onClick} />} */}
        {name == 'outline' && <MdOutlineAdd size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'move' && <BsCursorFill size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'save' && <AiOutlineSave size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'discard' && <ImCross size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'back' && <IoMdArrowRoundBack size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'camera' && <IoCameraOutline  size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'edit' && <CiEdit  size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'share' && <IoShareSocialOutline  size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'home' && <IoHome  size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'settings' && <IoIosSettings  size={size} color={choose? colorChoose:color} onClick={onClick} />}
        {name == 'scaner' && <BsQrCodeScan  size={size} color={choose? colorChoose:color} onClick={onClick} />}
        
</>

}








