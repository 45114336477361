import { useEffect, useState } from "react"

export default function useMobile(){
    const [isMobile, setIsMobile] = useState(false);
        useEffect(() => {
            const appVersion = window.navigator.appVersion || '';
            const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(appVersion);
            setIsMobile(isMobileDevice);
    },[])
    return{isMobile}
}