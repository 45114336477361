import React, { useEffect, useRef, useState } from 'react';
import jsQR from "jsqr";
import { useNavigate } from 'react-router-dom';
import BackButton from './BackButton';
import useMobile from './useMobile';
let constraints = {};
if (typeof window !== 'undefined') {
  constraints = {
    video: {
      aspectRatio: { ideal: 9 / 16 },
      facingMode: { ideal: "environment" },
      width: { ideal: window ? (window?.orientation === 1 ? 950 : 1920) : null },  // Adjust resolution for performance
      height: { ideal: window ? (window?.orientation === 1 ? 1920 : 950) : null },
      focusDistance: { ideal: 1.5 },
      brightness: { ideal: 100 }, // Try adjusting brightness
      contrast: { ideal: 200 },   // Try adjusting contrast
      exposureMode: "manual", // This may not be supported, check capabilities first
      exposureTime: { ideal: 0.025 }, // Exposure in seconds, may not be supported
    }
  };
}

function CameraScanner() {
  const videoRef = useRef(null);
  const {isMobile} = useMobile()
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const navigate = useNavigate()
  useEffect(() => {
    if (isCameraActive) {
      // navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          videoRef.current.srcObject = stream;
          // videoRef.current.play();
        })
        .catch((error) => console.error("Error accessing camera:", error));
    }

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        let stream = videoRef.current.srcObject;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isCameraActive]);
  useEffect(()=>{
    setIsCameraActive(true)
  },[])
  const captureAndScan = () => {
    const canvas = document.createElement("canvas");
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    const qrCode = jsQR(imageData.data, canvas.width, canvas.height,{inversionAttempts:"dontInvert"});
    console.log(qrCode)
    if (qrCode) {

      setScanResult(`QR Code: ${qrCode.data}`);
      console.log(qrCode.data)
      // https://avigamliel-backend.xyz/events/12312/12312
          navigate(`/events${qrCode.data.split("events")[1]}`)
      return;
    }
  };
  return (
     <div className="flex flex-col h-[100vh] w-[100%]">
{isMobile &&<BackButton color="white" />}
      <div
          style={{ zIndex: 1111 }}
          className="absolute top-0 left-0   flex  bg-black bg-opacity-80 flex-row w-full h-[50px]"
        >
            </div>
      {isCameraActive && (
     <video
     ref={videoRef}
     autoPlay
     playsInline
     style={{
      //  transform: 'scaleX(-1)',
       width: "100%", // Fill the width of the container
       height: "100vh", // Fill the height of the container
       objectFit: "contain", // Maintain aspect ratio without distortion
       position: "absolute",
       top: 0,
       left: 0,
       zIndex: 1,
       // filter:'contrast(1.0) brightness(1.0)', 
       backgroundColor: "#141c27", // Ensure background is black for letterboxing effect
     }}
   />
      )}
      <p>Scan Result: {scanResult}</p>
      <div
          style={{ zIndex: 1111 }}
          className="absolute bottom-0 left-0   flex bg-black bg-opacity-80 flex-row w-full h-[200px]"
        >
          <div className="w-full h-full  flex justify-center items-center">

          <div
            // style={{ position: 'absolute', bottom: 0, left: '25%', zIndex: 555555 }}
            className="w-full h-full   flex justify-center items-center"
          >
            <svg
              onClick={() => captureAndScan()}
              width="80"
              height="80"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50" fill="white" />
            </svg>
          </div>
          </div>
        </div>
    </div>
  );
}

export default CameraScanner;