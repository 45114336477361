
import { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { addNewEvent } from "../services";
import Row from "./Row";
import Icon from "./subComp/Icon";
import axios from "axios";

export default function BuildEvents({ setTypeBoard, setEvents }) {
  const [daysForDataBackup, setDaysForDataBackup] = useState("14-days");
  const [totalPrice, setTotalPrice] = useState(0);
  // const [priceItems, setPriceItems] = useState(0);
  const [priceItems, setPriceItems] = useState([]);

  const [rooms, setRooms] = useState([
    {
      id: 0,
      name: "room 1",
      startTime: new Date().toISOString().slice(0, 19).replace("T", " "),
      endTime: new Date().toISOString().slice(0, 19).replace("T", " "),
      listGuestFile: null,
      numGuests: 500,
      password: "",
    },
  ]);

  const handleOptionChange = (e) => {
    setDaysForDataBackup(e.target.value);
  };
  const goPayment = async () => {
    const payload = {
      // event: props.event,
      rooms,
    };
    console.log("goPayment");
    // send to server the data
    // const res = await addNewEvent(rooms, target.event_name.value, target.event_location.value, target.event_type.value, target.event_admins.value, localStorage.getItem("token"))
    // if (res.status) {
    //     setTypeBoard('events')
    // }
    // const res = await axios.post("/api/rooms/create_event", payload, {
    //   headers: {
    //     token: localStorage.getItem("token"),
    //   },
    // });
    // console.log(res)
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("goPayment");
    const target = e.target;

    const res = await addNewEvent(
      rooms,
      target.event_name.value,
      target.event_location.value,
      target.event_type.value,
      target.event_admins.value,
      localStorage.getItem("token")
    );
    if (res.status) {
      setTypeBoard("events");
    }

    console.log("CREATE NEW EVENT");
  };

  useEffect(() => {
    console.log(rooms);
    setPriceItems((prev) => {
      const index = prev.findIndex((p) => p.type == "rooms");
      if (index != -1) {
        prev[index].sum = rooms.length * 5;
      } else {
        prev.push({
          type: "rooms",
          sum: 0,
          count: rooms.length * 3,
        });
      }

      return [...prev];
    });
  }, [rooms]);

  useEffect(() => {
    if (daysForDataBackup !== "14-days") {
      setPriceItems((prev) => {
        let sum = 0;
        const index = prev.findIndex((p) => p.type == "backups");
        if (daysForDataBackup == "month") sum = 5;
        if (daysForDataBackup == "six-month") sum = 26;
        if (daysForDataBackup == "custom") sum = 20;
        if (index != -1) {
          prev[index].sum = sum;
        } else {
          prev.push({
            type: "backups",
            sum: sum,
            count: 1,
          });
        }

        return [...prev];
      });
    }
  }, [daysForDataBackup]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < priceItems.length; i++) {
      const element = priceItems[i];
      count += element.sum;
    }
    setTotalPrice(count);
  }, [priceItems]);

  return (
    <form 
    onSubmit={onSubmit}
    
    className="flex justify-center flex-col items-center w-[100%] bg-gray-200 px-4">
        <h4 className="font-bold underline mb-8">Insert Event Details</h4>
   
        <div className="flex justify-center flex-row items-center w-[100%] ">

     <div className="h-[100%]  w-[70%] flex justify-center">

     <div
        className="flex flex-col justify-start items-start w-[550px] h-[600px] bg-gray-200 overflow-auto gap-4"
      >
<div className="flex flex-col w-[100%] ">
<div className="h-[50px] w-[100%]">

           <h2 class="text-base font-semibold leading-7 text-gray-900 ">
            Event Details
          </h2>
          </div>
<div className="flex text-sm flex-row flex-wrap gap-2 w-[100%]">
        <input
          placeholder="Event Name"
          name="event_name"
          className="
         w-[calc(50%-4px)]
         p-1
          rounded-md border border-gray-300 
          focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent 
          text-gray-800
          transition-colors duration-200
        "
        />

        <input
          placeholder="Event Location"
          name="event_location"
          className="
          w-[calc(50%-4px)]
        p-1
          rounded-md border border-gray-300 
          focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent 
          text-gray-800
          transition-colors duration-200
        "
        />
        <input
          placeholder="Event Type"
          name="event_type"
          className="
          w-[calc(50%-4px)]
             p-1
          rounded-md border border-gray-300 
          focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent 
          text-gray-800
          transition-colors duration-200
        "
        />
        <input
          placeholder="Event Admins"
          name="event_admins"
          className="
          w-[calc(50%-4px)]
            p-1
         rounded-md border border-gray-300 
          focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent 
          text-gray-800
          transition-colors duration-200
        "
        />
        </div>
</div>

          <div className="w-[550px] p-2 text-sm flex flex-col bg-[#ffffff] rounded-[5px]" >

        <div className="w-[100%] flex flex-row justify-between items-center">
          <h5>Rooms</h5>
          {/* <FaPlus
            size={30}
            onClick={() => {
              setRooms((prev) => [
                ...prev,
                {
                  id: 0,
                  name: `room ${rooms.length + 1}`,
                  startTime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                  endTime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                  listGuestFile: null,
                  numGuests: 500,
                  password: "",
                },
              ]);
            }}
          /> */}
          <Icon name="plus" size={20} onClick={() => {
              setRooms((prev) => [
                ...prev,
                {
                  id: 0,
                  name: `room ${rooms.length + 1}`,
                  startTime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                  endTime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                  listGuestFile: null,
                  numGuests: 500,
                  password: "",
                },
              ]);
            }} />
</div>

             <div>
          <table>
            <thead>
              <tr  className="w-[100%] flex flex-row gap-2 justify-center items-center">
                <td 
                  style={{width:100}}
                  className="px-1 text-start"> Room name </td>
                <td  style={{width:100}}
                  className="px-1 text-start"> Start Date </td>
                <td  style={{width:100}}
                  className="px-1 text-start">  End Date </td>
                <td  style={{width:50}}
                  className="px-1 text-start"> Guests </td>
                <td  style={{width:100}}
                  className="px-1 text-start"> Password </td>
              </tr>
            </thead>
            <tbody className="flex flex-col gap-1">
              {rooms &&
                rooms.map((room, indexRoom) => {
                  return (
                    <tr key={`table-room-${indexRoom}`} className="w-[100%] flex flex-row gap-2 justify-center items-center bg-[#f5f5f5] rounded-[5px]">
                      <td>
                        <input
                              style={{width:100}}
                        className="px-1 text-start bg-[#f5f5f5]"
                          autoComplete="true"
                          placeholder=""
                          value={room?.name}
                          onChange={(e) => {
                            setRooms((prev) => {
                              prev[indexRoom].name = e.target.value;
                              return [...prev];
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                                                   style={{width:100}}
                        className="px-1 text-start bg-[#f5f5f5]"
                          autoComplete="true"
                          placeholder="Start Event"
                          type="date"
                          value={room?.startDate}
                          onChange={(e) => {
                            setRooms((prev) => {
                              prev[indexRoom].startDate = e.target.value;
                              return [...prev];
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                         style={{width:100}}
                        className="px-1 text-start bg-[#f5f5f5]"
                          autoComplete="true"
                          placeholder="End Event"
                          type="date"
                          value={room?.endDate}
                          onChange={(e) => {
                            setRooms((prev) => {
                              prev[indexRoom].endDate = e.target.value;
                              return [...prev];
                            });
                          }}
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        <input
                        style={{width:50}}
                        className="px-1 text-center bg-[#f5f5f5]"
                          autoComplete="true"
                          placeholder="500"
                          value={room?.numGuests}
                          onChange={(e) => {
                            setRooms((prev) => {
                              prev[indexRoom].numGuests = e.target.value;
                              return [...prev];
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          autoComplete="true"
                          type="password"
                                          style={{width:100}}
                        className="px-1 text-start"
                          placeholder="Create Password"
                          value={room?.password}
                          onChange={(e) => {
                            setRooms((prev) => {
                              prev[indexRoom].password = e.target.value;
                              return [...prev];
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        </div>
       
        <div class="border-b w-[100%] border-gray-900/10   pb-12">
          <h2 class="text-base font-semibold  text-gray-900 ">
            Backup
          </h2>
          <p class=" text-sm  text-gray-600">
            By default the event images save for you for 14 days after the
            events finish.
          </p>

          <div class=" ">
            <fieldset>
              <legend class="text-sm font-semibold  text-gray-900">
                plans
              </legend>
              <div class="flex flex-row gap-4">
                <div class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      id="14-days"
                      name="14-days"
                      type="radio"
                      value="14-days"
                      checked={daysForDataBackup === "14-days"}
                      onChange={handleOptionChange}
                      className="form-radio text-blue-500"
                    />
                    {/* <input id="14-days" name="14-days" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> */}
                  </div>
                  <div class="text-sm ">
                    <label for="14-days" class="font-medium text-gray-900">
                      14 days
                    </label>
                    <p class="text-white text-weight-800 bg-green-600 rounded text-center">
                      Free
                    </p>
                  </div>
                </div>
                <div class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      id="month"
                      name="month"
                      type="radio"
                      value="month"
                      checked={daysForDataBackup === "month"}
                      onChange={handleOptionChange}
                      className="form-radio text-blue-500"
                    />
                    {/* <input id="month" name="month" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> */}
                  </div>
                  <div class="text-sm ">
                    <label for="month" class="font-medium text-gray-900">
                      1 month
                    </label>
                    <p class="text-gray-500">Extra 5$</p>
                  </div>
                </div>

                <div class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      id="six-month"
                      name="six-month"
                      type="radio"
                      value="six-month"
                      checked={daysForDataBackup === "six-month"}
                      onChange={handleOptionChange}
                      className="form-radio text-blue-500"
                    />
                    {/* <input id="comments" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> */}
                  </div>
                  <div class="text-sm ">
                    <label for="six-month" class="font-medium text-gray-900">
                      6 months
                    </label>
                    <p class="text-gray-500">Extra 26$</p>
                  </div>
                </div>
                <div class="relative flex gap-x-3">
                  <div class="flex h-6 items-center">
                    <input
                      id="custom"
                      name="custom"
                      type="radio"
                      value="custom"
                      checked={daysForDataBackup === "custom"}
                      onChange={handleOptionChange}
                      className="form-radio text-blue-500"
                    />
                    {/* <input id="candidates" name="candidates" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> */}
                  </div>
                  <div class="text-sm ">
                    <label for="custom" class="font-medium text-gray-900">
                      Custom
                    </label>
                    <p class="text-gray-500">20$ per month</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {/* <button
          type="submit"
          className="
            bg-indigo-600 text-white py-2 px-4 
            rounded-md hover:bg-indigo-700 focus:outline-none 
            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 
            transition duration-150 
          "
        >
          Create Event
        </button> */}
      </div>
      </div>

      <div className="h-[100%]  w-[30%] flex justify-center">
        <div class="pointer-events-auto max-w-md">
          <div class="flex h-[60%] flex justify-start flex-col w-[100%] bg-white rounded-[5px] w-full">
            <div class="flex-1 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <h2
                  class="text-lg font-medium text-gray-900"
                  id="slide-over-title"
                >
                  Shopping cart
                </h2>
              </div>
              <div class="">
                <div class="flow-root">
                  <ul role="list" class="-my-6 divide-y divide-gray-200">
                    {priceItems.map((item) => {
                      return <Row item={item} />;
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
              <div class="flex justify-between text-base font-medium text-gray-900">
                <p>Subtotal</p>
                <p>${totalPrice}</p>
              </div>
              <p class=".5 text-sm text-gray-500">
                Shipping and taxes calculated at checkout.
              </p>
              {/* <div class="" onClick={() => goPayment()}> */}
                <button
                //   href="#"
                type="submit"
                  class="flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700"
                >
                  Checkout
                </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      </div>

    </form>
  );
}
