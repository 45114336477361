import React, { useState } from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Home from "./components/Home";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import Edit from "./components/Edit";
import EntryFeed from "./components/EntryFeed";
import "./App.css";
import Room from "./components/Room";
import Camera from "./components/Camera";
import Feed from "./components/Feed";
import EmojiPicker from "./components/EmojiPicker";
import CameraScanner from "./components/CameraScanner";
import Activate from "./components/Activate";
// import EmojiPicker from './components/EmojiPicker';

function App() {
  const [lastImage, setLastImage] = useState(null);
  return (
    <Router>
      {/* <EmojiPicker/> */}
      <div className="flex flex-col w-[100%] h-[100vh] ">
        <Routes>
          {}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/scanner" element={<CameraScanner />} />
          <Route path="/edit" element={<Edit lastImage={lastImage} />} />
          <Route
            path="/camera"
            element={
              <Camera lastImage={lastImage} setLastImage={setLastImage} />
            }
          />

          <Route path="/register" element={<Register />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/events/:event_id/:room_id/feed" element={<Feed />} />
          {/* <Route path="/events/:event_id/:room_id/edit" element={<Edit lastImage={lastImage}/>} />
          <Route path="/events/:event_id/:room_id/camera" element={<Camera lastImage={lastImage} setLastImage={setLastImage}  />} /> */}
          <Route path="/events/:event_id/:room_id" element={<EntryFeed />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
