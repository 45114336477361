import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFeedData } from '../services'

import Post from "./Post";
import Layout from "./Layout";

export default function Feed(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [images, setImages] = useState([])
    const [error, setError] = useState(null)

    const { event_id, room_id } = useParams();

    useEffect(() => {
        // const ws = new WebSocket('wss://avigamliel-backend.xyz');
        const ws = new WebSocket(`${window.location.protocol == "http:" ? 'ws' : 'wss'}://${window.location.host}`);
        ws.onopen = () => {
            if (!location.state) {
                if (!sessionStorage.getItem('ef-auth')) {
                    setError("Not Authorize")
                } else {
                    ws.send(JSON.stringify({ userId: sessionStorage.getItem('ef-auth'), eventId: event_id, roomId: room_id }));
                }
            } else {
                sessionStorage.setItem('ef-auth', location.state.userId)
                ws.send(JSON.stringify({ userId: location.state.userId, eventId: event_id, roomId: room_id }));
            }
            //     if(sessionStorage.getItem('auth'))
            //     setError("Not Authorize")
            // } else {
            //     ws.send(JSON.stringify({ userId: location.state.userId, eventId: event_id, roomId: room_id }));
            // }
        };

        // Handle incoming messages
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data)
            console.log("setImages")
            setImages(prev => [data.file, ...prev])
        };

        // Handle connection close
        ws.onclose = () => {
            console.log('Disconnected from the WebSocket server');
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
        const run = async () => {
            try {
                const res = await getFeedData(room_id, localStorage.getItem("token"))
              console.log(res);
                if(res.data.message !== "error"){
                    setImages(res.data.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        run()
    }, [])

    return <div className="flex flex-col justify-start items-center w-[100%] h-[100%] gap-8 overflow-scroll">
      <div className="absolute top-0 left-0 w-[100%] h-[50px] bg-black flex flex-row justify-start items-center">
        <span className="text-white" onClick={()=>navigate('/', {
            state:{room_id, event_id}
        })}>Logo</span>
      </div>
        <Layout  event_id={event_id} room_id={room_id}/>
        {/* <a href={`${window.location.pathname.split('/').slice(0, -1).join("/")}/camera`}>camera</a> */}
        {error ? <div>Not authorize</div> :
            images.length > 0 && images?.map((image, index) => {
                if (image) {
                    return <Post key={`post-${index}`} image={image} eventId={event_id} roomId={room_id} />
                } else {
                    return null
                }
            })
        }

    </div>
}