import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from "@react-oauth/google"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <GoogleOAuthProvider clientId='37370905130-mcqntv1slnkg82k8pvmf16h2rl37dh4q.apps.googleusercontent.com'>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode>, */}
  </GoogleOAuthProvider>
);

// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register()
reportWebVitals();
