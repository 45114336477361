import { useEffect, useState } from "react";
import { getInsideRoom } from "../services"
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function EntryFeed() {
    const { event_id, room_id } = useParams();
    const navigate = useNavigate()
    const [error , setError] = useState("")
    const onSubmit = async (e) => {

        e.preventDefault()
        // navigate(`/events/${event_id}/${room_id}/feed`, { userId:"test"})
        try {

            const res = await getInsideRoom(e.target.name.value, room_id, localStorage.getItem("token"))
            if (res.data) {
                localStorage.setItem("token", res.data.token)
                navigate(`/events/${event_id}/${room_id}/feed`, { state: { userId: res.data.userId } })
            }

        } catch (error) {
            setError("There is problem with the room")
console.log(error)
        }
    }

    useEffect(()=>{
        setTimeout(() => {
            setError(null)
        }, 8000);
    },[error])
    return <div className="w-[100%] h-[100vh] bg-gray-800 flex flex-col justify-center items-center  ">
        {
        error && 
        <div className="absolute top-10 w-[80%] p-4 bg-red-400 text-white rounded-[5px]">
       {error}
        </div>
        }
        <form className="w-[80%] bg-white p-4 rounded-[25px] flex flex-col" onSubmit={onSubmit}>
            <input placeholder="insert name" name="name" />
            <button type="submit">get in</button>
        </form>
    </div>
}