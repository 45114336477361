import React from "react";
import { useEffect, useState } from "react";
import Icon from "./subComp/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../services";

function ResetPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [msgToUser, setMsgToUser] = useState({ color: "", msg: "" });
  console.log(location.search);
  const validatepassword = (password) => {
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    // minimum 6 chars, maximum 16 chars
    //  (?=.*[0-9]) - Assert a string has at least one number;
    //  (?=.*[!@#$%^&*]) - Assert a string has at least one special character.

    return regularExpression.test(password);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("reset pass");
    const { password, repassword } = e.target;
    console.log(password.value, repassword.value);

    // validate password shape/strong
    console.log(location);
    let passwordChecked = validatepassword(password.value);
    if (!passwordChecked) {
      setMsgToUser({ color: "red", msg: "password not strong enough" });
      return;
    }

    // validate repassword shape/strong
    let repasswordChecked = validatepassword(repassword.value);
    if (!repasswordChecked) {
      setMsgToUser({ color: "red", msg: "repassword not strong enough" });
      return;
    }

    // validate passwords mached
    if (repassword.value !== password.value) {
      return setMsgToUser({ color: "red", msg: "passwords dont match" });
    }

    // פה צריך שליחה לשרת לצורך שינוי סיסמה בדאטה בייס ליוזר
    // אם לא הצליח - להחזיר הודעה מהסרבר לנציג אותה ליוזר
    // אם הצליח - הודעה ליוזר והעברה ללוגין פייג
    try {
      const res = await resetPassword(password.value, location.search);
      console.log(51, res.message);
      if (res.message == "success") {
        console.log("success");
        setMsgToUser({
          color: "green",
          msg: "Your password has been reset. moving to Login page",
        });
        navigate("/login");
      } else {
        console.log("failed");
        setMsgToUser({ color: "red", msg: "Process failed. Try agian" });
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
      setMsgToUser({ color: "red", msg: "Process failed. Try agian" });
    }
  };

  useEffect(() => {
    if (!msgToUser) return;
    const to = setTimeout(() => {
      setMsgToUser(null);
    }, 3000);
    return () => clearTimeout(to);
  }, [msgToUser]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Your Company"
          src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-1 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          <div>ResetPassword</div>
        </h2>
      </div>

      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={onSubmit} className="space-y-2">
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                rePassword
              </label>
            </div>
            <div>
              <input
                id="repassword"
                name="repassword"
                type="password"
                required
                autoComplete="current-repassword"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          {msgToUser ? (
            <div className="text-center" style={{ color: msgToUser.color }}>
              {msgToUser.msg}
            </div>
          ) : null}
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Reset My Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
