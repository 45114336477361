import { useNavigate } from "react-router-dom";
import Icon from "./subComp/Icon";

export  default function BackButton (props){
    const {color, sticky, onClick} = props
    const navigate = useNavigate()
    return (
        <div style={{zIndex:100001}}className=" top-0 h-[40px] flex flex-row justify-start">

        <div
            style={{ zIndex: 5 }}
            className="p-1 rounded-[5px] gap-2  flex justify-center items-center flex-row text-black">
            <Icon name="back" size={25} color={color? "white":"black"} onClick={() => onClick ? onClick() :navigate(-1)} />
          </div>
          </div>
    )
}