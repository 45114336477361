export const dic  = {
    "לחייך": "smile",
    "שמח": "happy",
    "פנים": "face",
    "ששון": "joy",
    "חייך": "grin",
    "צוחק": "laughing",
    "זיעה": "sweat",
    "הקלה": "relief",
    "דמעות": "tears",
    "כעוס": "angry",
    "עצוב": "sad",
    "מאוכזב": "disappointed",
    "מודאג": "worried",
    "מבולבל": "confused",
    "עייף": "tired",
    "מאושר": "happy", // Note: Duplicate keys may lead to data loss
    "בוכה": "crying",
    "לב": "heart",
    "אהבה": "love",
    "שבור": "broken",
    "אש": "fire",
    "כוכב": "star",
    "קשת": "rainbow",
    "מים": "water",
    "בלון": "balloon",
    "עוגה": "cake",
    "מתנה": "gift",
    "ספרים": "books",
    "כתיבה": "writing",
    "דוא״ל": "email",
    "כסף": "money",
    "מפתח": "key",
    "מחשב": "computer",
    "מוזיקה": "music",
    "תווים": "note",
    "חגיגה": "celebration",
    "מסיבה": "party",
    "ריקוד": "dance",
    "שינה": "sleep",
    "חבר": "friend",
    "משפחה": "family",
    "מאושר": "happy", // Note: Duplicate keys may lead to data loss
    "בכי": "cry",
    "ששון": "joy", // Note: Duplicate keys may lead to data loss
    "קריצה": "wink",
    "נשיקה": "kiss",
    "חיבוק": "hug",
    "אצבעות למעלה": "thumbs up",
    "אצבעות למטה": "thumbs down",
    "אוקי": "ok",
    "סימון": "check",
    "צלב": "cross"
  };
  