import React from "react";
import { useState, useEffect } from "react";
import useMobile from "./useMobile";
import Icon from "./subComp/Icon";
import { forgotPassword } from "../services";
import { useNavigate } from "react-router-dom";

function ForgotPassword(props) {
  const [msgToUser, setMsgToUser] = useState({ color: "", msg: "" });
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email } = e.target;

    console.log("ENTER send mail to user for reset password");
    console.log(email.value);

    //  בסרבר - צריך לבדוק אם היוזר קיים.
    //  אם לא קיים - להחזיר לקליינט שלא קיים.
    //  אם קיים - לשלוח לו מייל ולהחזיר לקליינט שנשלח מייל
    try {
      const res = await forgotPassword(email.value);
      console.log(res);
      if (res.data) {
        console.log("success");
        setMsgToUser({
          color: "green",
          msg: `we've sent you a mail to ${email.value}`,
        });
      } else {
        console.log("failed");
        setMsgToUser({ color: "red", msg: "Process failed. Try agian" });
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
      setMsgToUser({ color: "red", msg: "Process failed. Try agian" });
    }
  };

  useEffect(() => {
    if (!msgToUser) return;
    const to = setTimeout(() => {
      setMsgToUser(null);
    }, 5000);
    return () => clearTimeout(to);
  }, [msgToUser]);
  return (
    <div className="flex flex-col bg-white w-[100%] justify-center items-center rounded-[5px]">
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="m-3 p-3">
          <label htmlFor="email" className="block font-medium leading-6">
            Enter your account email
          </label>
          <div>
            <input
              id="email"
              name="email"
              type="email"
              required
              autoComplete="email"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {msgToUser ? (
          <div className="text-center" style={{ color: msgToUser.color }}>
            {msgToUser.msg}
          </div>
        ) : null}

        <div className="p-3">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Send me reset mail
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
