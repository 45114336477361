import data from '@emoji-mart/data'
import { init, SearchIndex } from 'emoji-mart'
import { useEffect, useState ,useRef} from 'react'
import { dic } from './dictionary'
import axios from 'axios'
import { categories } from './all'
init({ data })
function containsHebrew(text) {
    // Regex to match any Hebrew characters
    const hebrewRegex = /[\u0590-\u05FF]/;

    return hebrewRegex.test(text);
}
async function search(value) {
    const heb = containsHebrew(value)

    if (heb) value = dic[value.trim()]
    const emojis = await SearchIndex.search(value)
    const results = emojis.map((emoji) => {
        return emoji.skins[0].native
    })

    return results
}


export default function EmojiPicker({ onClickEvent, setToggleEmoji, type, categoriesEmoji }) {
    const [data, setData] = useState([])
    const [categoriesState, setCategoriesState] = useState([])
    const imgRef = useRef()

    useEffect(() => {
        const newArr = []

        if (type == "emoji") {

            categories.forEach(category => {
                let newObj = {}
                // newObj[category.id] = []
                newObj.id = category.id
                newObj.data = []
                category.emojis.forEach(async emoji => {
                    const str = await search(emoji)
                    newObj.data.push(str[0])
                })
                newArr.push(newObj)
            })
            setCategoriesState(newArr)
        } else if (type == "sticker") {


            const getData = async () => {
                const res = await axios.get("/api/stickers/get_list")
                return await res.data
            }
            const data_ = getData()
            data_.then(res => {
                console.log(res.data)
                setData(res.data)
            })
        }

    }, [type])

    const onSubmit = async (e) => {
        e.preventDefault()
        if (type == "emoji") {
            console.log(await search(e.target.icon.value))
            setData(await search(e.target.icon.value))
        } else if (type == "sticker") {
            //

        }
    }
    return (<div 
    style={{zIndex:6, position:'absolute'}}
    className='w-[100%] mt-24  rounded-[5px] flex justify-center flex-col items-center'>
        <div className='w-[80%] bg-white rounded-[5px] shadow-lg flex justify-center flex-col items-center p-8'>

            <form onSubmit={onSubmit} className='w-[100%] flex'>
                <input className='flex-1' placeholder='insert icon' name="icon" />
                <button type="submit">search</button>
            </form>
            <div className='w-[100%] flex gap-2 flex-col '>
                <div className='w-[100%] flex flex-row justify-between'>
                    {categoriesEmoji.map((item, index) => {
                        return <div
                            onClick={async () => setData(categoriesState.filter(p => p.id == item.id)[0].data)}
                            className='p-2'>{item.icon}</div>
                    })}
                </div>
                <div className='w-[100%] h-[400px] flex gap-2 flex-row overflow-scroll flex-wrap'>
                    {type == "emoji" ? (data.length > 0 && data.map((icon) => {
                        return <div className='flex basis-1' style={{ fontSize: 28 }}
                            onClick={() => {
                                onClickEvent(icon)
                                setToggleEmoji(false)
                            }}
                        >{icon}</div>
                    })) :
                        type == "sticker" ?
                            (data.length > 0 && data.map((icon) => {
                                return <>
                                    {/* <img src={`${window.location.origin}/api/stickers/get_sticker/${icon}`} /> */}
                                    <img
                                        ref={imgRef}
                                        onClick={(e) =>
                                            onClickEvent(icon)
                                        }
                                        className="w-auto h-[70px]" src={`https://avigamliel-backend.xyz/api/stickers/get_sticker/${icon}`} />
                                </>
                            })
                            )
                            : null}
                </div>
            </div>
        </div>
    </div>

    )

}

