import { useState } from "react";
import DrawerMenu from "./DrawerMenu";
import Layout from "./Layout";
import { useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();
  const { event_id, room_id } = location?.state || {};
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="bg-white h-[100%]">
      <header className="absolute inset-x-0 top-0 z-50 text-sm">
        qa test 2222
        <nav
          className="flex items-center justify-between px-6 lg:px-8 pt-[24px]  "
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setToggleMenu(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a
              href="#"
              className="text-sm     font-semibold leading-6 text-gray-900"
            >
              Product
            </a>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Features
            </a>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Marketplace
            </a>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Company
            </a>
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end flex flex-row g-8">
            <a
              href="/register"
              className="text-sm font-semibold  text-gray-900 mx-4"
            >
              Register
            </a>
            <a href="/login" className="text-sm font-semibold  text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
      </header>
      {toggleMenu && <DrawerMenu setToggleMenu={setToggleMenu} />}
      <div className="relative isolate px-6 pt-14 lg:px-8 flex justify-center items-center h-[100%]">
        <div className="mx-auto max-w-2xl h-[100f%] flex justify-center items-center flex-col">
          {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            Announcing our next round of funding. <a href="#" className="font-semibold text-indigo-600"><span className="absolute inset-0" aria-hidden="true"></span>Read more <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div> */}
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
              EventsFeed
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              catch your moment event.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Try out 6
              </a>
              <a
                href="/scanner"
                className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Scanner
              </a>
              {event_id && room_id && (
                <a
                  href={`/events/${event_id}/${room_id}/feed`}
                  className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  back to feed
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
