import React from "react";
import { useState, useEffect } from "react";
import useMobile from "../useMobile";
import Icon from "../subComp/Icon";

function ModalContainer(props) {
  return (
    <div
      className="flex flex-col w-[100%] h-[100%] bg-gray-900 absolute left-[0px] top-[0px] bg-opacity-70 justify-center items-center drop-shadow-2xl px-6"
      style={{ zIndex: 100002 }}
    >
      <div className="flex flex-col bg-white w-[100%] justify-center items-center rounded-[5px] relative">
        <div className="flex mt-4 sm:mx-auto sm:w-full sm:max-w-sm absolute left-[16px] top-[0px]">
          <Icon
            name="discard"
            size={12}
            onClick={() => {
              props.setModalToggle(!props.modalToggle);
            }}
          />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default ModalContainer;
