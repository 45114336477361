import { loginUser, connectGoogle } from "../services";
import { useNavigate } from "react-router-dom";
// import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useState, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import Icon from "./subComp/Icon";
import ForgotPassword from "./ForgotPassword";
import ModalContainer from "./subComp/ModalContainer";
import BackButton from "./BackButton";
import Layout from "./Layout";
import useMobile from "./useMobile";

export default function Login() {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [modalToggle, setModalToggle] = useState(false);
  const { isMobile } = useMobile();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("codeResponse: ", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      console.log("user: ", user);

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              // Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          setProfile(res.data);
          const obj = {
            email: res.data.email,
            family_name: res.data.family_name,
            given_name: res.data.given_name,
            id: res.data.id,
            name: res.data.name,
            picture: res.data.picture,
            verified_email: res.data.verified_email,
          };
          // send to server to check user exit if not save the data and to get token //
          const user_ = await connectGoogle(obj);
          console.log(user_);
          //move to dashbaord with data
          localStorage.setItem("token", user_.data);
          navigate("/dashboard");
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  useEffect(() => {
    logOut();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = e.target;
    const res = await loginUser(email.value, password.value);
    if (res.status == 0) {
      console.log("success");
      localStorage.setItem("token", res.data.token);
      navigate("/dashboard");
    } else {
      console.log("failed");
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 p-16">
      {isMobile && <BackButton />}
      {modalToggle && (
        <ModalContainer
          modalToggle={modalToggle}
          setModalToggle={setModalToggle}
        >
          <ForgotPassword />
        </ModalContainer>
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Your Company"
          src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>
      {/* {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <button onClick={() => login()}>Sign in with Google 🚀 </button>
            )} */}
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={onSubmit} className="space-y-2">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                <a
                  onClick={() => setModalToggle(!modalToggle)}
                  href="#"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </form>
        <div className="w-[100%] mt-2 rounded-2 bg-gray-100 relative flex flex-row  justify-center items-center p-2 shadow text-sm font-semibold">
          <button onClick={() => login()}>Sign in with Google</button>
          <div className="absolute right-4 ">
            <FcGoogle />
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{" "}
          <a
            href="/register"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Register
          </a>
        </p>
      </div>
    </div>
  );
}
