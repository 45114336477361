import { useEffect } from "react"
import { getEventData } from '../services'
import Icon from "./subComp/Icon"
import { useNavigate } from "react-router-dom"
export default function Events({ events, setEvents, setType }) {
const navigate= useNavigate()
    useEffect(() => {
        // get events data
        const run = async () => {
            const resultData = await getEventData(localStorage.getItem("token"))
            setEvents(resultData.data)
        }
        run()
    }, [])
    return (
        <div className={`flex flex-row w-[100%] h-[100%] flex-wrap gap-8 p-8`}>
            {events.length >0?

            events?.map((room, index) => {
            return <div key={index} className="flex  flex-col w-[140px] h-[200px] bg-white"
            onClick={()=>navigate(`/events/${room.event_id}/${room.room_id}`)}
            >
                <img src={`${window.location.origin}/api/images/${room.room_id}/QR_${room.room_id}.png`} />
                <p>
                    {room.event_name}
                </p>
                {room.room_max_guest}
                <p style={{ fontSize: 8 }}>{window.location.origin}/events/{room.event_id}/{room.room_id}</p>
            </div>
            
        })
    :null}
        </div>
    )
}