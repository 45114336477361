import { useEffect, useState } from "react";
import Icon from "./subComp/Icon";
import { useNavigate } from "react-router-dom";

export default function FooterDashboard(props) {
    const { room_id, event_id , type, setType} = props
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        const appVersion = window.navigator.appVersion || '';
        const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(appVersion);
        setIsMobile(isMobileDevice);
    }, []);
    if (!isMobile) return null
    return (
        <div className="z-10 absolute text-sm bottom-0 flex flex-row justify-around items-center gap-4 left-0 w-[100%] h-[70px] bg-gray-900 ">
            <div
                style={{ zIndex: 5 }}
                className="p-1 text-sm rounded-[5px] w-[100px] h-[30px] px-4 gap-2 flex justify-center items-center flex-row text-white">
                <Icon name="home" size={25} color="white" choose={type=="events"} onClick={() => {setType("events") }} />
            </div>

            <div
                style={{ zIndex: 5 }}
                className="p-1 text-sm rounded-[5px] w-[100px] h-[30px] px-4 gap-2 flex justify-center items-center flex-row text-white">
                <Icon name="settings" size={25} color="white" choose={type=="settings"} onClick={() => {setType("settings")}} />
            </div>

        </div>
    )
}